import React, {useState} from "react";
import truncate from "html-truncate";

export const ShowMoreHtml = ({
                                 text,
                                 maxLength = 100,
                                 moreText = 'Show more',
                                 lessText = 'Show less',
                                 className = '',
                                 anchorClass = '',
                                 expanded = false
                             }) => {

    const [showExpanded, setShowExpanded] = useState(expanded);
    let truncatedText = text ? truncate(text, maxLength) : '';

    let toggleExpanded = (e) => {
        e.preventDefault();
        setShowExpanded((!showExpanded))
    };

    return (
        text ?
            <div className={className}>
                <div dangerouslySetInnerHTML={{__html: showExpanded ? text : truncatedText}}/>
                <a className={anchorClass} href="#" onClick={toggleExpanded}>{showExpanded ? lessText : moreText}</a>
            </div> : null
    );

};
