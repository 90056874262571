import React, { useState } from "react";
import { Row, Col, Modal} from "react-bootstrap";
import Button from '../buttons/button';
import validator from "validator";
import "../newsletter/newsletter.css";
import { apiRequest } from "../../apiRquest/apiRequest";

const NewsLetter = ({Tittle,Text, ClassNameDisplay, ClassNameInput, ButtonStyle, ButtonText, IDCampaignAction}) => {
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");
    const [statusMsg, setStatusMsg] = useState("");
    const [successResponse, setSuccessResponse] = useState("");
    const [modalShow, setModalShow] = React.useState(false);
    const [suscribeBtnEnable, setSuscribeBtnEnable] = React.useState(true);
  const submit = () => {
        if (validator.isEmail(email)) {
            //alert("EMAIL: " + email);
            setSuscribeBtnEnable(false)
            apiRequest.post("/v1/contacts/send", {idCampaignAction: IDCampaignAction,  newsletter: "true", email_personal: email })
            .then(() => {
                setStatusMsg('Los datos se han enviado correctamente.<br /><strong>Gracias por suscribirte.</strong>');
                setSuccessResponse(true);
                setModalShow(true);
                setEmail('')
                //alert("gracias por suscribirte!!")
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'newsletter_suscription'
                });
                setSuscribeBtnEnable(true)
            }).catch(err => {
              // console.log(err);
                setSuccessResponse(false);
                setStatusMsg('Hubo un error de conexión.<br />Por favor intenta mas tarde.');
                setModalShow(true);
              //alert("hubo un error al suscribirte intenta mas tarde")
            })
            setEmailError("");
        } else {
            setEmailError("Ingrese un Email válido");
        }
    };


    return (
        <>
            <Row className={`${ClassNameDisplay} newsletter align-content-start align-items-md-end`}>
                <Col xs={12} md={5}>
                    <div className="inner-1">
                        <h4 className="volanta" style={{
                            color: "var(--secondary)"
                        }}>
                            {Tittle ? Tittle : "SUSCRIBITE AL NEWSLETTER"}
                        </h4>
                        <p className="texto mb-2" style={{
                            color: "var(--primary)"
                        }}>
                            <span dangerouslySetInnerHTML={{__html: Text}}/>
                            {/*Recibí nuestra información <br /> y enterate de las últimas novedades*/}
                        </p>
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className="inner-2">
                        <input
                            className={`${ClassNameInput} py-1 mb-1 mt-2`}
                            type="email"
                            value={email}
                            placeholder="Ingresá tu mail"
                            onChange={(e) => setEmail(e.target.value)}
                            style={{colot: "black"}}
                        />

                    </div>
                    <div
                        style={{
                            fontWeight: "300",
                            color: "red",
                            position: "absolute",
                            fontSize: "80%"
                        }}
                    >
                        {emailError}
                    </div>
                </Col>
                <Col xs={12} md={2}>
                    <div className="inner-1 mt-3">
                        {
                            suscribeBtnEnable ? (
                                <Button
                                    variant={ButtonStyle}
                                    text={ButtonText}
                                    onClick={() => {
                                        submit();
                                    }}
                                />
                            ) : (
                                <Button
                                    variant="disabled"
                                    text="Enviando..."
                                    onClick={() => {
                                        return false
                                    }}
                                    disabled={"disabled"}
                                />
                            )
                        }

                    </div>
                </Col>
            </Row>
            <Modal className="formmodal" show={modalShow} onHide={() => setModalShow(false)}
                   size="lg"
                   aria-labelledby="contained-modal-title-vcenter"
                   centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>{successResponse ? 'Muchas gracias' : 'Atención'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div dangerouslySetInnerHTML={{__html: statusMsg}}/>
                </Modal.Body>
            </Modal>
        </>
  );
};

export default NewsLetter;
